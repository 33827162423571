import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as reduxTypes from './types';

const NotificationMessages: Record<string, string> = {
  'auth/confirmAccount/fulfilled': 'Your account has been created. Please sign in below to complete the onboarding process.',
  'auth/confirmAccount/rejected': 'The code you entered was incorrect. Please try again.',
  'auth/forgotPassword/rejected': 'Username not found.  Please try again.',
  'auth/removeMfa/fulfilled': 'Successfully removed your MFA device!',
  'auth/removeMfa/rejected': 'There was an error updating your MFA device.  Please try again.',
  'auth/resendConfirmationCode/fulfilled': 'Successfully sent verification code! Please make sure to check your spam folder.',
  'auth/updateMfaPreference/fulfilled': 'Successfully updated your MFA preferences!',
  'auth/updateMfaPreference/rejected': 'There was an error updating your MFA preferences.  Please try re-entering the code from your MFA app.',
  'invoicesApi/confirmSelectiveInstantPayout/fulfilled': 'Successfully submitted your invoices for Get Paid Now!',
  'invoicesApi/confirmSelectiveInstantPayout/rejected': 'There was an error confirming invoices for Get Paid Now. Please try again later.',
  'invoicesApi/confirmSelectiveOnDemandPayout/fulfilled': 'Successfully submitted your invoices for On Demand payout!',
  'invoicesApi/confirmSelectiveOnDemandPayout/rejected': 'There was an error confirming invoices for On Demand payout. Please try again later.',
  'invoicesApi/bulkConfirmInstantPayout/fulfilled': 'Successfully submitted your invoices for Get Paid Now!',
  'invoicesApi/bulkConfirmInstantPayout/rejected': 'There was an error confirming invoices for Get Paid Now. Please try again later.',
  'invoicesApi/bulkConfirmOnDemandPayout/fulfilled': 'Successfully submitted your invoices for bulk payout!',
  'invoicesApi/bulkConfirmOnDemandPayout/rejected': 'There was an error confirming invoices for bulk payout. Please try again later.',
  'invoicesApi/confirmInstantPayout/fulfilled': 'Successfully submitted your invoice for Get Paid Now!',
  'invoicesApi/confirmInstantPayout/rejected': 'There was an error confirming Get Paid Now. Please try again later.',
  'invoicesApi/confirmOnDemandPayout/fulfilled': 'Successfully confirmed On Demand payout!',
  'invoicesApi/confirmOnDemandPayout/rejected': 'There was an error confirming On Demand payout. Please try again later.',
  'invoicesApi/updateVendorClientData/fulfilled': 'Successfully updated client name!',
  'invoicesApi/updateVendorClientData/rejected': 'There was an error updating client name.  Please try again momentarily.',
  'paymentsApi/createDirectDebitAuthorization/rejected': 'There was an error while creating direct debit authorization. Please contact support at support@joinmesa.com',
  'vendorApi/confirmVerification/fulfilled': 'Successfully submitted the micro-deposits posted to your account!',
  'vendorApi/confirmVerification/rejected': 'There was an error submitting the micro-deposit amounts provided. Please try again momentarily.',
  'vendorApi/createChildUserAccount/fulfilled': 'Success! An invitation email has been sent to the specified email address.',
  'vendorApi/createChildUserAccount/rejected': 'Could not invite user.  Please ensure you are an admin and try again.',
  'vendorApi/createSecondaryBankAccount/fulfilled': 'Successfully added new bank account!',
  'vendorApi/createSecondaryBankAccount/rejected': 'There was an error adding your new bank account. Please try again momentarily.',
  'vendorApi/getVendor/error': 'There was an error retrieving your business information.',
  'vendorApi/getVendorAccounts/error': 'There was an error retrieving your banking information.',
  'vendorApi/patchVendorFeatures/fulfilled': 'Successfully updated your preferences!',
  'vendorApi/patchVendorFeatures/fulfilled/enabled-auto-get-paid-now': 'Successfully updated your preferences! To turn this feature off, visit Account > Payment Schedule.',
  'vendorApi/patchVendorFeatures/rejected': 'There was an error updating your preferences. Please try again momentarily.',
  'vendorApi/requestInflights/fulfilled': 'Successfully requested invoices for acceleration!',
  'vendorApi/requestInflights/rejected': 'There was an error requesting invoices for acceleration. Please try again momentarily.',
  'vendorApi/signVendorPartnerServiceAgreement/fulfilled': 'You successfully signed your Supplemental Service Agreement!',
  'vendorApi/signVendorPartnerServiceAgreement/rejected': 'There was an error signing your Supplemental Service Agreement. Please try again momentarily.',
  'vendorApi/signVendorServiceAgreement/fulfilled': 'You successfully signed your Mesa Service Agreement!',
  'vendorApi/signVendorServiceAgreement/rejected': 'There was an error signing your Mesa Service Agreement. Please try again momentarily.',
  'vendorApi/updateVendor/fulfilled': 'Successfully updated your business information!',
  'vendorApi/updateVendor/rejected': 'There was an error updating your preferences. Please try again momentarily.',
  'vendorApi/updateVendorPreferences/fulfilled': 'Successfully updated your preferences!',
  'vendorApi/updateVendorPreferences/rejected': 'There was an error updating your preferences. Please try again momentarily.',
  'vendorApi/uploadVendorDocument/fulfilled': 'Successfully uploaded your document!',
  'vendorApi/uploadVendorDocument/rejected': 'There was an error submitting your verification document. Please try again momentarily.',
};

type NotificationInfo = {
  type: 'success' | 'error';
  name: string;
  message: string;
};

type NotificationsState = NotificationInfo[];

const initialState: NotificationsState = [];

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    dismissNotification(
      state: NotificationsState,
      action: PayloadAction<{ type: 'success' | 'error', name: string }>,
    ) {
      const { type, name } = action.payload;
      const idx = state.findIndex((notification) => notification.name === name && notification.type === type);

      state.splice(idx, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher((action: PayloadAction<{ type: 'success' | 'error', name: string }>) => reduxTypes.isRejectedAction(action), (state, action: any) => {
        const message = NotificationMessages[action.type] || action.error?.message || 'An unknown error ocurred';

        state.push({
          type: 'error',
          name: action.type,
          message,
        });

        // Additional matchers for rtk-query types,
        // where the base actions are not unique and must be parsed.
        //
        // E.g.:
        // For vendorApi/executeQuery/getVendor:
        // vendorApi/executeQuery/fulfilled -> vendorApi/getVendor/fulfilled
        if (action?.meta?.arg?.endpointName) {
          const { arg: { endpointName }, requestStatus } = action.meta;
          const rtkType = action.type.split('/')[0];
          const rtkQueryName = `${rtkType}/${endpointName}/${requestStatus}`;
          const fixedCacheKey = action?.meta?.arg?.fixedCacheKey;
          const fixedCacheMessageKey = `${rtkQueryName}/${fixedCacheKey}`;
          const queryMessage = NotificationMessages[fixedCacheMessageKey] || NotificationMessages[rtkQueryName] || action.error?.message || 'An unknown error ocurred';

          state.push({
            type: 'error',
            name: rtkQueryName,
            message: queryMessage,
          });
        }
      })
      .addMatcher((action) => reduxTypes.isFulfilledAction(action), (state, action: any) => {
        const message = NotificationMessages[action.type] || action.error?.message || 'Success!';

        state.push({
          type: 'success',
          name: action.type,
          message,
        });

        if (action?.meta?.arg?.endpointName) {
          const { arg: { endpointName }, requestStatus } = action.meta;
          const rtkType = action.type.split('/')[0];
          const rtkQueryName = `${rtkType}/${endpointName}/${requestStatus}`;
          const fixedCacheKey = action?.meta?.arg?.fixedCacheKey;
          const fixedCacheMessageKey = `${rtkQueryName}/${fixedCacheKey}`;
          const queryMessage = NotificationMessages[fixedCacheMessageKey] || NotificationMessages[rtkQueryName] || action.payload?.message || 'Success!';

          state.push({
            type: 'success',
            name: rtkQueryName,
            message: queryMessage,
          });
        }
      });
  },
});

export const { dismissNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
