import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BackButtonContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 8px;
  border: 1px solid #E1E4EA;
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  color: #525866;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.084px;
  line-height: 20px;
  padding: 6px 10px;
  transition: background 300ms ease-in-out;

  &:hover {
    background: #F9FAFB;
  }
`;

const BackIcon = styled.img`
  margin-right: 6px;
`;

const BackButtonLocations = new Set([
  '/onboarding/payment-schedule',
  '/onboarding/demo-payment-schedule',
  '/onboarding/pay-on-approval',
  '/onboarding/net-30-payment-schedule',
  '/onboarding/auto-get-paid-now',
  '/onboarding/on-demand-schedule',
  '/onboarding/bank-info',
  '/onboarding/direct-debit-agreement',
  '/onboarding/terms',
  '/onboarding/jll-terms',
]);

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const showBackButton = useMemo(() => BackButtonLocations.has(location.pathname), [location]);

  if (!showBackButton) {
    return null;
  }

  return (
    <BackButtonContainer onClick={() => navigate(-1)}>
      <BackIcon src="/assets/back-icon.svg" />
      Back
    </BackButtonContainer>
  );
}

export default BackButton;
