import React, { ReactElement } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ErrorScreen, StyleSheetManager, theme } from '@mesa-labs/mesa-ui';

import Routes from '../routes';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  #modal-root {
    position: fixed;
    z-index: 10;
  }

  body {
    font-family: ${(props) => props.theme.fonts.inter};
    padding: 0px;
    margin: 0px;

    input:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }

  .pac-container {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    box-shadow: none;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    margin-top: 4px;

    .pac-item {
      border: none;
      padding: 4px;
    }
  }
`;

const Layout = styled.div`
  font-size: 16px;
  height: 100%;
`;

function App(): ReactElement {
  return (
    <StyleSheetManager>
      <ThemeProvider theme={theme}>
        <Layout id="mesa-ui">
          <Router>
            <GlobalStyle />
            <Sentry.ErrorBoundary fallback={<ErrorScreen onClick={() => Sentry.showReportDialog()} />}>
              <Routes />
            </Sentry.ErrorBoundary>
          </Router>
        </Layout>
      </ThemeProvider>
    </StyleSheetManager>
  );
}

export default App;
