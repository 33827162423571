import { useMemo } from 'react';

const BaseSteps = [{
  name: 'Business',
  routes: ['/onboarding/business-info'],
  step: 0,
  visible: true,
}];

const ClassicPaymentScheduleStep = {
  name: 'Payment Schedule',
  routes: ['/onboarding/payment-schedule', '/onboarding/demo-payment-schedule', '/onboarding/auto-get-paid-now', '/onboarding/pay-on-approval', '/onboarding/net-30-payment-schedule'],
  step: 1,
  visible: true,
};

const OnDemandPaymentScheduleStep = {
  name: 'Payment Schedule',
  routes: ['/onboarding/on-demand-schedule'],
  step: 1,
  visible: true,
};

const DefaultConfirmationSteps = [{
  name: 'Banking',
  routes: ['/onboarding/bank-info', '/onboarding/direct-debit-agreement', '/onboarding/verify-bank'],
  step: 2,
  visible: true,
}, {
  name: 'Confirmation',
  description: 'Terms & Conditions',
  routes: ['/onboarding/review', '/onboarding/terms', '/onboarding/jll-terms'],
  step: 4,
  visible: false,
}];

const CustomMsaConfirmationSteps = [{
  name: 'Confirmation',
  routes: ['/onboarding/bank-info', '/onboarding/verify-bank'],
  // todo
  step: 3,
  visible: false,
}];

function useProgressBarSteps(onDemand: boolean, customMsa: boolean) {
  const stepContent = useMemo(() => [
    ...BaseSteps,
    ...(onDemand ? [OnDemandPaymentScheduleStep] : [ClassicPaymentScheduleStep]),
    ...(customMsa ? CustomMsaConfirmationSteps : DefaultConfirmationSteps),
  ], [onDemand, customMsa]);

  return stepContent;
}

export default useProgressBarSteps;
