import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OnboardingState {
  onboardingId?: string;
  customMsa: boolean;
  programOverrides: readonly api.OnboardingProgramOverrideResponse[];
  feeScheme?: api.FeeScheme;
  partnerId: api.Partners;
  instantPayoutAlwaysOn?: boolean;
  programCode: api.ProgramCodes | undefined;
  promoCode: api.PromoCodes | undefined;
  skipAutoInstantPayoutStep?: boolean;
  subdomain?: string;
  restrictToPrograms: api.OnboardingResponse['restrictToPrograms'];
}

const initialState: OnboardingState = {
  onboardingId: undefined,
  customMsa: false,
  programOverrides: [],
  feeScheme: api.FeeScheme.DISCOUNTING,
  partnerId: api.Partners.JLL,
  instantPayoutAlwaysOn: false,
  programCode: undefined,
  promoCode: undefined,
  skipAutoInstantPayoutStep: false,
  subdomain: undefined,
  restrictToPrograms: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateOnboardingId(state: OnboardingState, action: PayloadAction<string>) {
      state.onboardingId = action.payload;
    },
    updateCustomMsa(state: OnboardingState, action: PayloadAction<boolean>) {
      state.customMsa = action.payload;
    },
    updateProgramOverrides(state: OnboardingState, action: PayloadAction<readonly api.OnboardingProgramOverrideResponse[]>) {
      state.programOverrides = action.payload;
    },
    updateFeeScheme(state: OnboardingState, action: PayloadAction<api.FeeScheme>) {
      state.feeScheme = action.payload;
    },
    updatePartnerId(state: OnboardingState, action: PayloadAction<api.Partners>) {
      state.partnerId = action.payload;
    },
    updateInstantPayoutAlwaysOn(state: OnboardingState, action: PayloadAction<boolean>) {
      state.instantPayoutAlwaysOn = action.payload;
    },
    updateProgramCode(state: OnboardingState, action: PayloadAction<api.ProgramCodes>) {
      state.programCode = action.payload;
    },
    updatePromoCode(state: OnboardingState, action: PayloadAction<api.PromoCodes>) {
      state.promoCode = action.payload;
    },
    updateSkipAutoInstantPayoutStep(state: OnboardingState, action: PayloadAction<boolean>) {
      state.skipAutoInstantPayoutStep = action.payload;
    },
    updateSubdomain(state: OnboardingState, action: PayloadAction<string>) {
      state.subdomain = action.payload;
    },
    updateRestrictToPrograms(state: OnboardingState, action: PayloadAction<api.OnboardingResponse['restrictToPrograms']>) {
      state.restrictToPrograms = action.payload;
    },
  },
});

export const {
  updateOnboardingId,
  updateCustomMsa,
  updateProgramOverrides,
  updateFeeScheme,
  updatePartnerId,
  updateInstantPayoutAlwaysOn,
  updateProgramCode,
  updatePromoCode,
  updateSkipAutoInstantPayoutStep,
  updateSubdomain,
  updateRestrictToPrograms,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
