import {
  Api, AuthenticationOptions, getDefaultClient, InvoiceApi, PaymentApi, types as api, VendorApi,
} from '@mesa-labs/mesa-api';
import {
  AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse,
} from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import cognitoProvider from '../../cognito';

export const getIdToken = async (): Promise<string | undefined> => {
  try {
    return await cognitoProvider.getIdToken();
  } catch (err) {
    return undefined;
  }
};

export const prepareHeaders = async (headers: Headers) => {
  const idToken = await getIdToken();

  if (idToken) {
    headers.set('Authorization', `Bearer ${idToken}`);
  }

  headers.set('mode', 'cors');
  headers.set('cache', 'no-cache');
  headers.set('credentials', 'same-origin');
  headers.set('Accept', 'application/json');
  // headers.set('Content-Type', 'application/json');

  return headers;
};

export const ensureIdToken = async (): Promise<string | undefined> => {
  let session = await cognitoProvider.getCurrentSession();
  if (!session?.isValid()) {
    session = await cognitoProvider.refreshCurrentSession();
  }

  return session?.isValid() ? (await session?.getIdToken())?.getJwtToken() : undefined;
};

export const getHeaders = () => ({
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

type ApiCtor<T extends Api> = new (baseURL: string, options?: AuthenticationOptions, client?: AxiosInstance) => T;

const UnauthorizedErrorsForLogout = new Set([
  'NotAuthorizedException',
]);

export const useApi = <T extends Api>(baseURL: string, Ctor: ApiCtor<T>, dispatch: ThunkDispatch<any, any, any>, authenticated = true) => {
  const client = getDefaultClient(baseURL, getHeaders());

  if (authenticated) {
    client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      const idToken = await ensureIdToken();

      if (idToken) {
        config.headers.set('Authorization', `Bearer ${idToken}`);
      }
      return config;
    });
  }

  client.interceptors.response.use(
    (response: AxiosResponse) => ({
      ...response,
      data: response.status === 404 ? undefined : response.data,
    }),
    async (err?: AxiosError) => {
      const statusCode = err?.response?.status;
      const errorName = err?.name || '';
      // Ensure that we only sign out on 403 if we are actually missing the Auth header
      if ((statusCode === 401 && UnauthorizedErrorsForLogout.has(errorName)) || (statusCode === 403 && !err?.config?.headers?.Authorization)) {
        // TODO: This is to avoid a dependency cycle with the auth slice, which also depends on vendorApi -> api
        await cognitoProvider.signOut();
        return dispatch({ type: 'auth/signOut/fulfilled' });
      }

      throw api.ApiError.fromAxiosError(err);
    },
  );

  return new Ctor(baseURL, undefined, client);
};

export const useVendorApi = (dispatch: ThunkDispatch<any, any, any>) => useApi(CONFIG.api.vendorUrl, VendorApi, dispatch);
export const useInvoiceApi = (dispatch: ThunkDispatch<any, any, any>) => useApi(CONFIG.api.invoiceUrl, InvoiceApi, dispatch);
export const usePaymentApi = (dispatch: ThunkDispatch<any, any, any>) => useApi(CONFIG.api.paymentUrl, PaymentApi, dispatch);
